<template>
    <section class="relative min-h-full w-full bg-gray-50">
        <slot v-if="state.mounted" />

        <div
            class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white bg-opacity-60"
            v-if="state.loading"
        >
            <span
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            >
                <base-loading size="lg" />
            </span>
        </div>
    </section>
</template>

<script setup>
const emitter = useEmitter()

const state = reactive({
    loading: false,
    mounted: false,
})

onMounted(() => {
    emitter.on('set-loading', setLoading)
    state.mounted = true
})

onBeforeUnmount(() => {
    emitter.off('set-loading', setLoading)
    state.mounted = false
})

const setLoading = (value) => {
    state.loading = value
}
</script>
